<template>
  <div 
    class="v-select"
    :class="{
        'is-fill': !(selected == ''), 
        'is-open': isOpen,
        'invalid': hasErrors || errors,
        'valid': !errors && options[selected],
        'disabled': disabled
    }"
  >


        <FormulateInput
            ref="input"
            v-show="false"
            type="text"
            v-model="options[selected]"
            :validation="validation"
            @validation="hasErrors = $refs.input.hasErrors"
            error-behavior="submit"
        />


      <p class="v-select-label">{{  selectLabel  }}</p>
      <p 
        :id="field"
        @click="openSelect"
        class="v-select-value"
      >
        {{  options[selected] || emptyText  }}
      </p>


      <transition name="select">
        <div 
            v-if="this.areOptionsVisible"
            class="v-select-options"
        >
            <div class="v-select-options-wrap">
                <div class="v-select-options-block">
                    <p
                        v-for="(option, index) in Object.keys(options)"
                        :key="index"
                        @click="selectOption(option)"
                    >
                        {{ options[option] }}
                    </p>
                </div>
                
            </div>          
      </div>
    </transition>
      
      <div 
        v-if="errors"
        class="v-select-error"
      >
        <p>{{ errors }}</p>
      </div>
  </div>
</template>

<script>
export default {
    name: 'v-select',
    props: {
        emptyText: String,

        validation: Array,

        options: {
            type: Object,
            default: {}
        },
        field: {
            type: String,
            default: '',
            require: true
        },
        selected: {
            type: String,
            default: ''
        },
        selectLabel: {
            type: String,
            default: ''
        },
        errors: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },


    data() {
        return {
           hasErrors: false,

           areOptionsVisible: false,
           isOpen: false,
           selectSuccess: false
        }
    },
    methods: {
        openSelect() {
            this.areOptionsVisible = !this.areOptionsVisible;
            this.isOpen = !this.isOpen;
        },
        selectOption(option) {
            this.$emit('select', {option, field: this.field}); // add to multiply fields
            this.areOptionsVisible = false
            this.isOpen = false
        },
        hideSelect() {
            this.areOptionsVisible = false
            this.isOpen = false
        }
    },

    mounted() {
        document.addEventListener('click', (e) => {

            if(e.target.id !== this.field){
              
                this.hideSelect()
            }

        })
    },

    beforeDestroy() {
        document.removeEventListener('click', this.hideSelect)
    }
    
}
</script>

<style lang="scss" scoped>

@import "@/styles/setup/_variables.scss";

.v-select.disabled {
    opacity: .3;
    pointer-events: none;
    cursor: default;
}
.v-select.is-fill.valid {
    .v-select-value {
        border-color:  $success;
    }
}
.v-select.invalid {
    .v-select-value {
        border-color:  $error;
    }
}
.v-select.is-fill {
    // .v-select-value {
    //     border-color:  $text-light;
    // }
   .v-select-label {
       top: 5rem;
       font-size: 11rem;
       line-height: 12rem;
   } 
}
// .v-select.is-fill:not(.valid):not(.invalid), 
.v-select.is-open {
    .v-select-value {
        border-color:  $text-light;
    }
}
.v-select {
    position: relative;
    cursor: pointer;
    width: 100%;
    user-select: none;
    &::after {
        position: absolute;
        content: '';
        width: 24rem;
        height: 24rem;
        top: 50%;
        right: 16rem;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDEwSDhMMTIgMTRMMTYgMTBaIiBmaWxsPSIjNjY3QzlFIi8+Cjwvc3ZnPgo=");
        background-position: center center;
        background-repeat: no-repeat;
        pointer-events: none;
        z-index: 98;
    }
    &-value {
        position: relative;
        width: 100%;
        padding-left: 16rem;
        padding: 16rem;
        padding-right: 48rem;
        padding-bottom: 4rem;
        height: 42rem;
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis;
        background-color: $white;
        outline: none;
        border: 1rem solid $light-gray;
        width: 100%;
        border-radius: 8rem;
        transition: $default;
        color: $text-main;
        font-size: 14rem;
        line-height: 20rem;
        z-index: 98;
        &:hover {
            border-color: $text-light;
        }
    }
    &-label {
        position: absolute;
        content: '';
        top: 11rem;
        left: 12rem;
        display: block;
        font-size: 14rem;
        line-height: 20rem;
        color: $text-light;
        padding: 0 4rem;
        pointer-events: none;
        z-index: 99;
    }
    &-options {
        position: absolute;
        content: '';
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 97;
        background-color: $white;
        &-wrap {
            margin-top: 3rem;
            padding: 8rem; 
            border-radius: 8rem;
            box-shadow: $btn-shadow-light;
        }
        &-block {
            max-height: 141rem;
            overflow: auto;
            scrollbar-color: rgba($text-main, .3) transparent;
            scrollbar-width: thin;
            &::-webkit-scrollbar-button {
                width: 4rem;
                height: 8rem;
            }
            &::-webkit-scrollbar-track {
                border-radius: 2rem;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 2rem;
                background-color: rgba($text-main, .3);
            }
            &::-webkit-scrollbar {
                width: 4rem;
            }
            p {
                border-radius: 8rem;
                transition: $cubic;
                font-size: 14rem;
                line-height: 24rem;
                padding: 4rem 16rem;
                color: $text-main;
                &:hover {
                    background-color: $background;
                }
            }
        }
    }
    &-error {
        position: absolute;
        content: '';
        top: 42rem;
        left: 0;
        width: 100%;
        font-size: 12rem;
        line-height: 16rem;
        color: $error;
    }
    .select-enter-active {
        transition: $cubic;
    }
    .select-leave-active {
        transition: $cubic;
    }
    .select-enter, .select-leave-to {
        transform: translateY(-10rem);
        opacity: 0;
    }
}
</style>