<template>

  <div class="suggestion-block">

    <FormulateInput
        id="workPlace"
        name="workPlace"
        type="text"
        v-model="modelData[prop]"
        maxLength="100"
        :label="label"
        class="big"
        @focus="() => visibleList = true"
        @blur="noActiveList"
        :disabled="disabled"
        :validation="validation"
        error-behavior="submit"
        v-doubleDisable
        v-changeFocusBlur
        autocomplete="off"
    />

    <transition name="fade" appear>

      <div v-if="data.length && visibleList" class="result-items">

        <div v-for="(item, key) of data" 
            :key="key" @click="selected(item)" 
            class="one-item" 
            name="one-item"
        >

          {{item.NAME}}

        </div>
      </div>

    </transition>

  </div>

</template>

<script>


export default {

  name: 'vSuggestionInput',

  props: {
    label: String,
    prop: String,
    modelData: Object,
    listData: Object | Array,
    dataId: Object,
    dataIdProp: String,
    disabled: Boolean,
    validation: Array
  },

  computed: {
 
    setVariable(){

      return this.modelData[this.prop]

    },



  },   

  watch: {

    "setVariable"(){

      if(!Array.isArray(this.full_list)){
        return;
      }

      this.data = this.full_list.filter((item) => {

        const hasItem = item.NAME.toLowerCase().includes(this.modelData[this.prop].toLowerCase())

        return hasItem
          
      })

    },

    "listData"(){
      this.full_list = this.listData
    }

  },

  data(){


    return {

      data: [],

      full_list: this.listData,

      visibleList: false,
      selecting: false,

    }

  },

  mounted(){

    document.onclick = (e) => {

      if(e.target.id !== 'workPlace' && this.data.length){

        this.modelData[this.prop] = this.data[0].NAME
        this.dataId[this.dataIdProp] = this.data[0].ID
      }

    }


  },

  methods: {
    
    selected(item) {
      
      this.modelData[this.prop] = item.NAME;
      this.dataId[this.dataIdProp] = item.ID

      this.selecting = true

    },

    noActiveList(e){

      if(this.selecting || e.target.name !== 'one-item'){

        this.visibleList = false

        this.$nextTick(() => {
          this.selecting = false
        })

      }

    }

  },


}
</script>

<style lang="scss" scoped>

@import "@/styles/setup/_variables.scss";


  .suggestion-block{

    position: relative;

  }
  .result-items{
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    max-height: 122rem;
    width: 100%;
    overflow-y: auto;
    border: 1px solid $text-medium;

    display: block;
    background: $white;
    z-index: 20;
    scrollbar-color: rgba($text-main, .3) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar-button {
        width: 4rem;
        height: 8rem;
    }
    &::-webkit-scrollbar-track {
        border-radius: 2rem;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2rem;
        background-color: rgba($text-main, .3);
    }
    &::-webkit-scrollbar {
        width: 4rem;
    }

  }

  .one-item{
    color: #506586;
    font-size: 14rem;
    line-height: 20rem;
    padding: 5rem 10rem;
    cursor: default;

  }

</style>