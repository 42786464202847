<template>

    <div class="grid-dashboard grid-column-3 grid-inputs">

        <div v-if="coincidenceBlock" class="grid-item">
            <div class="wrapper-checkbox inline" v-doubleDisable>
                <label class="checkbox">
                    <input
                        type="checkbox"
                        v-model="coincidenceBlock.model[coincidenceBlock.propModel]"
                        :disabled="coincidenceBlock.disabled"
                        @change="coincidenceBlock.changeEvent"
                    >
                    <div></div>
                    <p>{{ coincidenceBlock.title }}</p>
                </label>
            </div>

        </div>


        <div class="grid-item" :class="attr.wrapperClasses">
            <FormulateInput
                type="text"
                :name="prop"
                :ref="prop"
                :label="attr.label"
                :validation="attr.validation"
                maxlength="200"
                v-model="data[prop]"
                :disabled="attr.disabled"
                name-help="ADDRESS"
                error-behavior="submit"
                v-changeFocusBlur
                v-doubleDisable
                class="big"
                @blur="notArea"
                autocomplete="new-password"
            />
        </div>
<!-- :disabled="attr.disabled" -->

        <div class="grid-item grid-dashboard checkbox-block">
            <div class="grid-item">
                <div class="wrapper-checkbox inline" v-doubleDisable>
                    <label class="checkbox">
                        <input
                            type="checkbox"
                            v-model="checkState.street"
                            @change="notArea"
                            :disabled="attr.disabled"
                        >
                        <div></div>
                        <p>Нет улицы</p>
                    </label>
                </div>
            </div>

            <div class="grid-item">
                <div class="wrapper-checkbox inline" v-doubleDisable>
                    <label class="checkbox">
                        <input
                            type="checkbox"
                            v-model="checkState.house"
                            @change="notArea"
                            :disabled="attr.disabled"
                        >
                        <div></div>
                        <p>Нет номера дома</p>
                    </label>
                </div>
            </div>

        </div>                        

    </div>

</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'v-no_street_house_input',

    props: {
        data: Object,
        prop: String,
        attr: Object,
        
        coincidenceBlock: {
            model: Object,
            propModel: String,
            disabled: Boolean,
            changeEvent: Function,
            title: String
        }
    },

    computed: {

        ...mapState({

            updateDadata:      state => state.dadataHelper.update,

        })


    },

    data(){

        const takeDadata = this.$cookies.get(this.prop) || '{}'

        return {
            takeDadata,

            checkState: {
                street: takeDadata.checkStreet || false,
                house: takeDadata.checkHouse || false
            },

            mapAddress: {
                street: null,
                house: null,
                flat: null
            },

            mapChanged: {
                street: null,
                house: null,
                flat: null
            }
        }

    },


    watch: {

        "updateDadata"(){

            this.notArea()

        } 

    },

    methods: {

        notArea(){

            this.mapAddress.street = null
            this.mapAddress.house  = null
            this.mapAddress.flat   = null

            setTimeout(() => {
                
                //адрес из кукк
                this.takeDadata = this.$cookies.get(this.prop) || '{}'
                
                const currData = this.takeDadata.full || ''


                const splitAddress = currData.includes(',')
                                            ? currData.split(',')
                                            : []

                const bitAddress = [
                                this.takeDadata.street, 
                                this.takeDadata.house,
                                this.takeDadata.flat
                            ]


                splitAddress.forEach((split, splitIndex) => {
                    bitAddress.forEach((bit, bitIndex) => {


                        if(bit === null) return;

                        if(split.includes(bit)){

                            switch(bitIndex){

                                case 0:
                                    this.mapAddress.street = splitIndex

                                    if(!this.checkState.street){
                                        splitAddress[splitIndex] = bit
                                    }
                                    break;

                                case 1:
                                    this.mapAddress.house = splitIndex

                                    if(!this.checkState.house){
                                        splitAddress[splitIndex] = bit
                                    }
                                    break;

                                case 2:
                                    this.mapAddress.flat = splitIndex

                                    if(!this.checkState.flat){
                                        splitAddress[splitIndex] = bit
                                    }
                                    break;

                            }


                        }


                    })
                })


// Тамбовская обл, Тамбовский р-н, зона Промышленная, д 1А

                if(!splitAddress.length) return;

                if(this.checkState.street && this.mapAddress.street !== null){

                    splitAddress[this.mapAddress.street] = ''
                    splitAddress[this.mapAddress.house] = ''
                    splitAddress[this.mapAddress.flat] = ''
                }

                if(this.checkState.house && this.mapAddress.house !== null){

                    splitAddress[this.mapAddress.house] = ''
                    splitAddress[this.mapAddress.flat] = ''
                }

                
                this.data[this.prop] = splitAddress
                                            .filter(item => item)
                                            .join(',')

                this.$cookies.set(this.prop, JSON.stringify({
                    ...this.takeDadata,
                    checkStreet: this.checkState.street,
                    checkHouse: this.checkState.house
                    })
                )


            }, 0)

        }
        

    },



}

</script>