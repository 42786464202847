<template>

    <div class="grid-item contract-code">

        <FormulateInput
            v-if="!expired && bitVisibleElement"
            type="text"
            name="code"
            label="Код из смс"
            :validation="parentScheme.data[parentScheme.name]"
            maxlength="5"
            v-model="model.code"
            v-mask="'#####'"
            v-changeFocusBlur
            autocomplete="off"
            class="big"
        />


        <div v-if="btnSend.buttons_2 === 'send'" class="grid-item input-send-button">
            <button
                @click="() => { btnSend.sendSms(); phone = data_2.mobile_phone }"
                :disabled="btnSend.disabled_block_2 || $toStringFromNull(btnSend.mobileData).length !== 15"
                class="btn-big btn-text primary"
            >
                Отправить код
            </button>
        </div>


        <div v-if="bitVisibleElement" class="grid-item input-confirm-button">

            <button @click="btnConfirm.confirmSms"
                    :disabled="btnConfirm.disabled"
                    class="btn-big btn-text primary"
            >
                Подтвердить
            </button>

        </div>

        <div 
            v-if="message"
            class="time-message"
        >
           <p v-html="message"></p>
        </div>

        <button v-if="!activeBtn && !expired && bitVisibleElement"
           href="#"
           class="btn-big btn-text-icon link statement-btn-request"
           @click.prevent="sendToServer('click')"
           
        >
            <span class="btn-icon">
                <svg>
                    <use xlink:href="@/assets/img/sprite.svg#rotate-icon"></use>
                </svg>
            </span>
            <span>Запросить код повторно</span>
        </button>

        


    </div>

</template>


<script>

import { mapState } from 'vuex'



export default {

    name: 'v-visual_sms_separate_sender',
    
    //дополнительные пропсы для двух кнопок ветки договоров btn
    props: {

        model: Object,
        conditionalSend: Boolean,
        client_phone: {
            type: String,
            required: false
        },
        mutationButton: Function,

        parentScheme: {
            name: String,
            data: Array
        },

        btnSend: { 
            buttons_2:        String, 
            sendSms:          Function, 
            disabled_block_2: Boolean,
            mobileData:       String
        },

        btnConfirm: { 
            buttons_2:  String, 
            confirmSms: Function, 
            disabled:   Boolean,
        }
    },

    data(){

        return {
            init: 'contract',
            message: '',
            expired: null,
            initSms: {
                count: +this.$cookies.get(`all_send_sms_${this.init}`)
            },
            // tick: 10,

        }

    },

    watch: {

        "contractTick": function(){

            if(!this.contractTick){

                this.message = null;
                this.expired = false

            }else if(this.contractTick > 0){


                this.message = `Код подтверждения истекает через <span>${this.contractTick}</span>` 

                this.expired = false

                this.mutationButton && this.mutationButton({ action: 'toContinue' })

            }

            if(this.contractTick === 1){
                this.initSms.count = +this.$cookies.get(`all_send_sms_${this.init}`)
            }


        },
        

    },

    computed: {


        ...mapState({
            contractTick:      state => state.sendSms.contractTick,
            activeBtn: state => state.sendSms.activeBtn,
            //error:     state => state.sendSms.error,

        }),

        bitVisibleElement(){
            return this.btnSend?.buttons_2 === 'confirm'
        }

    },


    methods: {
        
        async sendToServer(action){

            await this.$store.dispatch('sendSms/sendSms', {
                                    action: action,
                                    init: this.init
                                })

        },
        

    },

    async mounted(){


        if(this.conditionalSend && !this.initSms.count){
            
            await this.sendToServer('init')

        }

    }
}


</script>