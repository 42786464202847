<template>
    <div class="v-step-two-contract-wrap">
        <transition name="fade" appear>
            <div class="v-step-two-contract grid-dashboard area">
                <div class="v-step-two-contract-notifications grid-item wrapper-notifications steps">
                    <div class="wrapper-notifications-icon-step">
                        <svg>
                            <use xlink:href="../../assets/img/sprite.svg#icon-step2"></use>
                        </svg>
                    </div>
                    <p>Сбор данных клиента для оформления нового договора индивидульного пенсионного плана</p>
                </div>
                <div class="v-step-two-contract-smev grid-item ">
                    <FormulateForm  ref="twoForm"
                                    name="twoForm"
                                    #default="{ hasErrors }"
                                    :errors="errors"
                                    aria-autocomplete="off"
                    >

                        <FormulateErrors />
                        <div
                            v-if="smevStage >= 3"
                            class="wrapper-notifications success"
                        >
                            <p> Данные клиента успешно прошли проверку в информационных системах (СМЭВ МВД/ПФР)</p>
                        </div>
                        <div
                            v-if="smevStage === -1"
                            class="wrapper-notifications error"
                        >
                            <p>{{ }}</p>
                        </div>

                        <div class="wrapper-form-block area shadow-dark" :class="formClassTwo">
                            <p class="h4">Личные данные клиента</p>
                            <div class="grid-dashboard grid-column-3">
                                <div class="grid-item">
                                    <FormulateInput
                                        ref="last_name"
                                        type="text"
                                        name="last_name"
                                        label="Фамилия"
                                        :validation="localShemes.oneNameFio"
                                        maxlength="100"
                                        v-model.trim="data_1.last_name"
                                        v-mask="$masks.upperCyrilic"
                                        :disabled="disabled_block_1"
                                        error-behavior="submit"
                                        v-changeFocusBlur
                                        v-doubleDisable
                                        class="big"
                                        @input="checkBlock_1"
                                        autocomplete="new-password"

                                    />
                                </div>
                                <div class="grid-item">
                                    <FormulateInput
                                        ref="first_name"
                                        type="text"
                                        name="first_name"
                                        label="Имя"
                                        :validation="localShemes.oneNameFio"
                                        maxlength="100"
                                        v-model="data_1.first_name"
                                        v-mask="$masks.upperCyrilic"
                                        :disabled="disabled_block_1"
                                        error-behavior="submit"
                                        v-changeFocusBlur
                                        v-doubleDisable
                                        class="big"
                                        @input="checkBlock_1"
                                        autocomplete="new-password"

                                    />
                                </div>
                                <div class="grid-item wrapper-input-checkbox">
                                    <FormulateInput
                                        ref="middle_name"
                                        type="text"
                                        name="middle_name"
                                        label="Отчество"
                                        :validation="customSchemes.middleNameScheme"
                                        maxlength="100"
                                        v-model="data_1.middle_name"
                                        v-mask="$masks.upperCyrilic"
                                        :disabled="req_1.disName || disabled_block_1"
                                        error-behavior="submit"
                                        v-changeFocusBlur
                                        v-doubleDisable
                                        class="big"
                                        @input="checkBlock_1"
                                        autocomplete="off"

                                    />
                                    <div class="wrapper-checkbox inline" v-doubleDisable>
                                        <label class="checkbox">
                                            <input
                                                v-model="req_1.checkName"
                                                :disabled="req_1.disCheck || disabled_block_1"
                                                type="checkbox"
                                                label="Без отчества"
                                                @change="optionalMiddleName"
                                            >
                                            <div></div>
                                            <p>Без отчества</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="grid-item">
                                    <FormulateInput
                                        ref="dul_ser_passport"
                                        id="dul_ser_passport"
                                        type="text"
                                        name="dul_ser_passport"
                                        :validation="localShemes.passNumSeries"
                                        label="Серия и номер паспорта"
                                        :disabled="disabled_block_1"
                                        error-behavior="submit"
                                        v-model="data_1.dul_ser_passport"
                                        v-changeFocusBlur
                                        v-doubleDisable
                                        v-mask="'#### ######'"
                                        maxlength="11"
                                        class="big"
                                        @input="checkBlock_1"
                                        autocomplete="new-password"

                                    />
                                </div>

                                <div class="grid-item grid-dashboard grid-column-2">
                                    <div class="grid-item">

                                        <Celendar 
                                                ref="celendar"
                                                type="one" 
                                                :dates="data_1"
                                                prop="birth_date"
                                                name="birth_date"
                                                label="Дата рождения"
                                                :disabled="disabled_block_1"
                                                :validation="localShemes.birthDate"
                                                classes="big"
                                                :inputFunc="checkBlock_1"

                                        />


                                    </div>
                                    <div class="grid-item">
                                        <FormulateInput
                                            ref="snils"
                                            id="snils"
                                            type="text"
                                            name="snils"
                                            label="СНИЛС"
                                            :validation="localShemes.snils"
                                            maxlength="14"
                                            v-model="data_1.snils"
                                            v-mask="'###-###-### ##'"
                                            :disabled="disabled_block_1"
                                            error-behavior="submit"
                                            v-changeFocusBlur
                                            v-doubleDisable
                                            class="big"
                                            @input="checkBlock_1"
                                            autocomplete="new-password"

                                        />
                                    </div>
                                </div>
                                <div class="grid-item">
                                    <div class="area-buttons btns-end">
                                        <div class="area-buttons-block">
                                            <button :disabled="hasErrors
                                                || disabled_block_1
                                                || activePreloader
                                                || block_1HasErrors"
                                                    @click="preHandle"
                                                    class="btn-big btn-text primary"
                                                >Далее
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="loader">
                                <img src="@/assets/img/svg/preloader.svg" alt="">
                            </div>

                        </div>

                    </FormulateForm>
                </div>
                <transition name="fade">

                    <div v-if="activePreloader || smevStage !== 0"
                        class="v-step-two-contract-check-mobile grid-item"
                    >
                        <FormulateForm name="threeForm" aria-autocomplete="off" >

                            <FormulateErrors />
                            <div
                                class="wrapper-notifications success"
                                v-if="buttons_2 === 'success'"
                            >
                                <p>Номер телефона клиента успешно подтвержден</p>
                            </div>

                            <div
                                class="wrapper-notifications default"
                                v-if="buttons_2 === 'confirm'"
                            >
                                <p>На номер телефона <span style="font-weight: 600;">{{ data_2.mobile_phone }}</span> направлено смс с кодом для подтверждения. Запросите код подтверждения у клиента.</p>
                            </div>


                            <div class="wrapper-form-block area shadow-dark" :class="formClassThree">
                                <p class="h4">Проверка номера телефона клиента</p>

                                <div class="v-step-two-contract-check-mobile-block">
                                    <div class="grid-item input-mobile">
                                        <FormulateInput
                                            id="mobile_phone"
                                            type="text"
                                            name="mobile_phone"
                                            label="Мобильный телефон"
                                            :validation="customSchemes.phoneScheme"
                                            v-mask="'+7 ### ### ####'"
                                            maxlength="15"
                                            v-model="data_2.mobile_phone"
                                            error-behavior="submit"
                                            :disabled="disabled_block_2 || Boolean(~['confirm','success'].indexOf(buttons_2))"
                                            v-changeFocusBlur
                                            v-doubleDisable
                                            class="big"
                                            autocomplete="off"
                                        />
                                        <button
                                            v-if="buttons_2 === 'confirm'"
                                            class="btn-big btn-text link-inline"
                                            @click.prevent="buttons_2 = 'send'"
                                        >
                                            Изменить номер
                                        </button >
                                    </div>



                                    <div
                                        class="grid-item input-code"
                                        v-if="~['send', 'confirm'].indexOf(buttons_2)"
                                    >
                                        <vSmsVisualSeparateSender :model="codePuck"

                                                                nameForm="threeForm"
                                                                :conditionalSend="data_2.mobile_phone && buttons_2 === 'confirm'"

                                                                :parentScheme="{
                                                                    name: 'smsCode',
                                                                    data: localShemes
                                                                }"
                                                                :btnSend="{ 
                                                                    buttons_2, 
                                                                    sendSms,
                                                                    disabled_block_2,
                                                                    mobileData: data_2.mobile_phone
                                                                }"
                                                                :btnConfirm="{
                                                                    disabled: codePuck.code.length !== 5,
                                                                    confirmSms,
                                                                    buttons_2
                                                                }"
                                        />

                                    </div>
                                </div>
                            </div>
                        </FormulateForm>
                    </div>

                </transition>
                <transition name="fade">
                    
                    <div v-if="buttons_2 === 'success'"
                        class="v-step-two-contract-other-data grid-item"
                    >
                        <FormulateForm ref="thourForm"
                                    name="thourForm"
                                    submit.prevent
                                    @validation="monitorContinueLock"
                                    aria-autocomplete="off"
                        >
                            <FormulateErrors />
                            <div class="wrapper-form-block area shadow-dark" :class="formClassThour">
                                <p class="h4">Прочие данные</p>
                                <div class="grid-dashboard-form grid-column-6">


                                    <div class="grid-item">
                                        <vSelect
                                            :options="optionsGender"
                                            :selectLabel="'Пол'"
                                            :selected="data_3.gender"
                                            :validation="localShemes.gender"
                                            :disabled="disabled_block_3"
                                            field="gender"
                                            emptyText="Выберите пол"
                                            @select="optionSelect"
                                        />
                                    </div>
<!-- :errors="getCustomFieldError['gender']" -->


                                    <div class="grid-item issue-pasport">
                                        <FormulateInput
                                            type="text"
                                            name="dul_subdivision_title"
                                            label="Кем выдан паспорт"
                                            v-model="collab_1.dul_subdivision_title"
                                            :validation="localShemes.passTitle"
                                            name-help="fms_unit"
                                            ref="dul_subdivision_title"
                                            group_1="name"
                                            :disabled="disabled_block_3"
                                            v-maskSymbCyrilic
                                            error-behavior="submit"
                                            v-changeFocusBlur
                                            v-doubleDisable
                                            class="big"
                                            autocomplete="off"
                                        />
                                    </div>
                                    <div class="grid-item">
                                    <Celendar type="one" 
                                                :dates="data_3"
                                                prop="dul_date"
                                                name="dul_date"
                                                label="Дата выдачи"
                                                :disabled="disabled_block_3"
                                                :validation="localShemes.dulDate"
                                                classes="big"
                                        />
                                    </div>
                                    <div class="grid-item">
                                        <FormulateInput
                                            type="text"
                                            name="dul_subdivision_code"
                                            label="Код подразделения"
                                            :validation="localShemes.passCode"
                                            maxlength="7"
                                            v-model="collab_1.dul_subdivision_code"
                                            ref="dul_subdivision_code"
                                            name-help="fms_unit"
                                            group_1="code"
                                            :disabled="disabled_block_3"
                                            v-maskPassCode
                                            v-changeFocusBlur
                                            v-doubleDisable
                                            error-behavior="submit"
                                            class="big"
                                            autocomplete="off"
                                        />
                                    </div>


                                    <div class="grid-item place-birth">
                                        <FormulateInput
                                            type="text"
                                            name="birth_place"
                                            label="Место рождения"
                                            :validation="localShemes.placeBirth"
                                            v-model="data_3.birth_place"
                                            v-mask="$masks.upperSymbCyrilic"
                                            :disabled="disabled_block_3"
                                            error-behavior="submit"
                                            v-changeFocusBlur
                                            v-doubleDisable
                                            class="big"
                                            autocomplete="off"
                                        />
                                    </div>

                                    <div class="grid-item">
                                        <vSuggestionInput
                                            label="Место работы"
                                            :modelData="workPlace"
                                            prop="name"
                                            :listData="organizations"
                                            :validation="localShemes.workPlace"
                                            :dataId="data_3"
                                            dataIdProp="job_org_id"
                                            :disabled="disabled_block_3"
                                        />
                                    </div>


                                    <div class="grid-item">
                                        <FormulateInput
                                            name="email"
                                            type="text"
                                            label="E-mail"
                                            v-model="data_3.email"
                                            v-changeFocusBlur
                                            v-doubleDisable
                                            :disabled="disabled_block_3"
                                            :validation="localShemes.email"
                                            class="big"
                                            autocomplete="new-password"
                                        />
                                    </div>

                                    <div class="grid-item inn input-not-requaired">
                                        <FormulateInput
                                            name="inn"
                                            type="text"
                                            label="ИНН"
                                            v-mask="'############'"
                                            v-model="data_3.inn"
                                            v-changeFocusBlur
                                            v-doubleDisable
                                            :disabled="disabled_block_3"
                                            :validation="localShemes.inn"
                                            class="big"
                                            autocomplete="off"
                                        />
                                    </div>


                                </div>

                                <p class="h4">Адрес места регистрации</p>

                                <NoStreetHouseInput :data="data_3"
                                                    prop="registration_address"
                                                    ref="registration_address"
                                                    :attr="{
                                                        wrapperClasses: 'registration-address',
                                                        label:          'Адрес регистрации',
                                                        validation:     localShemes.address,
                                                        disabled:       disabled_block_3                                       
                                                    }"
                                /> 

                                <p class="h4 after-check">Адрес места проживания</p>

                                <NoStreetHouseInput :data="data_3"
                                                    prop="location_address"
                                                    ref="location_address"

                                                    :attr="{
                                                        wrapperClasses: 'place-residence',
                                                        label:          'Адрес места проживания',
                                                        validation:     customSchemes.locationAddressScheme,
                                                        disabled:       disabled_block_3 || req_2.disName                                     
                                                    }"

                                                    :coincidenceBlock="{
                                                        model:       req_2,
                                                        propModel:   'checkName',
                                                        disabled:    !req_2.disCheck && disabled_block_3,
                                                        changeEvent: optionalLocationAddress,
                                                        title:       'Совпадает с местом регистрации'
                                                    }"
                                />
                              
                            </div>

                        </FormulateForm>
                    </div>
                </transition>   
            
{{data_3}}
                <transition name="fade">
                    <div v-if="view_pay_block" 
                        class="v-step-two-contract-product grid-item"  
                        :class="formClassFive"
                    >


                        <FormulateForm ref="fiveForm"
                                name="fiveForm"
                                @validation="() => {
                                    errorsSyntax({}, 'fiveForm')
                                    monitorContinueLock()
                                }"
                                submit.prevent
                                aria-autocomplete="off"
                        >
                            <FormulateErrors />
                            
                            <div class="wrapper-form-block area shadow-dark">


                                <div class="grid-dashboard">
                                    <div class="grid-item grid-dashboard grid-column-3 product-autopayment">
                                        <div class="grid-item">
                                            <p class="h4">Продукт</p>

                                            <p v-if="productData">{{ productData[data_4.product_type_id].name }}. Данные клиента успешно прошли проверку в информационных системах (СМЭВ МВД/ПФР)</p>

                                        </div>
                                    </div>
                                    <div class="grid-item grid-dashboard product-print">
                                        <div class="grid-item">
                                            <p class="h4">Оплата</p>
                                            <p>Оплата банковской картой по ссылке на платежную страницу банка</p>
                                        </div>
                                        <div class="grid-item">
                                            <div class="grid-dashboard autopayment">
                                                <div class="grid-item">
                                                    <FormulateInput
                                                        ref="firstPay"
                                                        type="text"
                                                        name='firstPay'
                                                        label="Cумма взноса (руб.)"
                                                        v-model="data_4.vznos_summ"
                                                        :validation="localShemes.firstPay"
                                                        error-behavior="submit"
                                                        v-mask="'######'"
                                                        v-changeFocusBlur
                                                        v-doubleDisable
                                                        class="big"
                                                        autocomplete="off"
                                                    />
                                                </div>

                                                <div class="grid-item">
                                                    <div class="wrapper-switch" v-doubleDisable> 
                                                        <label class="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                v-model="pay.check_auto_pay"
                                                                @change="optionalDatePay"
                                                            >
                                                            <div></div>
                                                            <p>Подключить автоплатеж</p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <transition name="fade">
                                                    <div
                                                        v-if="pay.check_auto_pay"
                                                        class="gire-item grid-dashboard grid-column-2"
                                                    >
                                                        <div class="grid-item">

                                                            <Celendar type="one" 
                                                                      :dates="pay"
                                                                      prop="datePay"
                                                                      name="datePay"
                                                                      label="Дата списания платежа"
                                                                      liveBehavior="live"
                                                                      :validation="customSchemes.datePayScheme"
                                                                      classes="big"
                                                            />


                                                        </div>

                                                        <div class="grid-item">
                                                            <vSelect
                                                                :options="optionsAutopayment"
                                                                :selectLabel="'Периодичность автоплатежа'"
                                                                :selected="pay.period_auto_pay"
                                                                :disabled="disabled_block_4"
                                                                emptyText="Выберите период"
                                                                field="payment"
                                                                @select="optionSelect"
                                                            />

                                                        </div>
                                                    </div>
                                                </transition>
                                                
                                            </div>
                                        </div>
                                        <div class="grid-item">
                                            <div class="grid-dashboard-block">
                                                <p>Оплата по банковским реквизитам</p>
                                            <div class="btn-small btn-text-icon link-inline">
                                                    <span class="btn-icon">
                                                        <svg>
                                                            <use xlink:href="@/assets/img/sprite.svg#print-icon"></use>
                                                        </svg>
                                                    </span>
                                                    <span>Распечатать квитанцию</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            <!-- <p>Вид продукта {{ calcProduct() }}</p> -->


                            </div>
                        </FormulateForm>

                    </div>
                </transition>
                <div class="v-step-two-contract-buttons area-buttons btns-between grid-item">
                    <div class="area-buttons-block">
                        <router-link
                            :to="{name: 'contractId-1'}"
                            tag="button"
                            class="btn-big btn-text secondary"
                            >
                            Назад
                        </router-link>
                    </div>
                    <div class="area-buttons-block">
                        <button
                            @click="continueProject"
                            :disabled="continue_btn_lock"
                            class="btn-big btn-text primary"
                        >
                            Продолжить
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>    
</template>


<script>
import NoStreetHouseInput from '@/components/local/no_street_house_input'
import Celendar from '@/components/local/celendar'
import vSmsVisualSeparateSender from '@/components/local/sms_visual_sender/v-separate_sender'
import vSelect from "@/components/local/select"
import {mapGetters, mapState} from 'vuex'
import vSuggestionInput from '@/components/local/v-suggestion_input'

export default {
    name: 'v-step-3',

    props: {
        handleSubmit: Function,
        setDadataHelper: Function,
        getContract: null,
        clearProjectSms: Function
    },

    computed: {

        formClassTwo(){

            return {
                activePreloader: this.activePreloader && !~[-1, 3, 4].indexOf(this.smevStage),
                'form-fail':     this.smevStage === -1,
                'form-correct':  this.smevStage >= 3
            }

        },

        formClassThree(){

            //отчистка ошибки - сервис недоступен [код правки - 001]
            if(this.buttons_2 === 'success'){
                this.errorsAPI({}, 'threeForm')
            }

            return {
                'form-correct': this.buttons_2 === 'success',
                'form-fail':    this.errorsFromApi.threeForm
            }
        },

        formClassThour(){

            return {
                'form-correct': this.buttons_2 === 'success' && this.disabled_block_3,
                'form-fail':    this.errorsFromApi.thourForm
            }
        },

        formClassFive(){

            return {
                'form-correct': this.view_pay_block && this.disabled_block_4,
                'form-fail':    this.errorsFromApi.fiveForm
            }
        },


        ...mapState({

            smevStage:      state => state.checkSmev.stage,

            smsSendError:       state => state.sendSms.error,

            smsConfirmError:       state => state.confirmSms.error,
            smsConfirmData_errors: state => state.confirmSms.data_errors,

            finalError:             state => state.finalizeContract.error,
            finalData_errors:       state => state.finalizeContract.data_errors,

            productData:            state => state.getProducts.data

        }),




    },

    data(){

        const data_1 = {
            last_name: '',
            first_name: '',
            middle_name: '',
            snils: '',
            birth_date: '',
            dul_ser_passport: '',
        }

        const data_2 = {
            mobile_phone: '',
        }

        const data_3 = {
            inn: '',
            email: '',
            dul_date: '',
            birth_place: '',
            job_org_id: null,
            //мутируют в файле dadata_helper при выборе адресов
            registration_address: "",
            registration_kladr: "",
            registration_fias: "",
            registration_index: "",
            registration_json: {},
            gender: 'М',
            location_address: "",
            location_kladr: "",
            location_fias: "",
            location_index: "",
            location_json: {},
        }

        const data_4 = {
            scheme: null,
            vznos_summ: null,
            payment_method: '',
            product_type_id: null,
            product_name: '',
            pens_pay_period: 0,
            vznos_type: null,
            payment_date: ''
        }


        const collab_1 = {
            dul_subdivision_code: '',
            dul_subdivision_title: ''
        }

        const localShemes = this.$lodash.cloneDeep(this.$schemes)

        this.paymentString = ''

        return {
            
            errors: {}, // переменная для хранения ошибок

            optionsGender: {
                'М': 'Мужской',
                'Ж': 'Женский'
            },
            optionsAutopayment: {
                '30': 'Ежемесячно',
                '90': 'Ежеквартально'
            },

            activePreloader:   false,
            disabled_block_1:  false,
            disabled_block_2:  true,
            disabled_block_3:  true,
            disabled_block_4:  true,
            buttons_2:         'send',
            view_pay_block:    false,
            continue_btn_lock: true,

            data_1:   this.$fillActual(data_1, this.getContract.data),

            data_2:   {...this.$fillActual(data_2, this.getContract.data),
                        citizenship: "643",
                        dul_type: "21"
                    },
            data_3:   this.$fillActual({ ...data_3, ...collab_1}, this.getContract.data, undefined, { job_org_id: 'doc_data' }),
            
            workPlace: {
                name: ''
            },

            data_4:   this.$fillActual(data_4, this.getContract.data, 'doc_data'),
            collab_1: this.$fillActual(collab_1, this.getContract.data),
            codePuck: {
                code: this.fixationVerifyPhoneStage('getCode'),
            },
            pay: {
                check_auto_pay: this.$cookies.get('check-auto_pay') === 'true',
                period_auto_pay: '30',

                // новое поле
                datePay: '',
                // defaulStr: '',
                // ageClient: 24,
                // bank_name: 'bank',
            },

            check_boxes_step_2: this.$cookies.get('contract-check_boxes_step_2'),

            req_1: {
                reqName: true,
                disName: false,
                disCheck: false,
                checkName: this.$cookies.get('check-middle_name') === 'true'
            },

            req_2: {
                reqName: true,
                disName: false,
                disCheck: false,
                checkName: this.$cookies.get('check-location_address') === 'true'
            },

            localShemes,

            dataSchemes: {
                twoForm: ['oneNameFio', 'birthDate', 'passNumSeries', 'snils', 'middleNameScheme'],
                threeForm: ['phone', 'phoneScheme', 'smsCode'],
                thourForm: ['placeBirth', 'passCode', 'passTitle', 'dulDate', 'address', 'locationAddressScheme', 'email', 'gender', 'workPlace'],
                fiveForm: ['firstPay', 'datePay', 'datePayScheme']
            },

            customSchemes: {
                phoneScheme:    localShemes.phone.slice(0),
                middleNameScheme:   localShemes.oneNameFio.slice(0),
                locationAddressScheme: localShemes.address.slice(0),
                datePayScheme: localShemes.datePay.slice(0)
            },

            errorsFromApi: {
                twoForm: '',
                threeForm: '',
                thourForm: '',
                fiveForm: ''
            },
            //список работодателей соответствующие данному продукту
            organizations: {},

            block_1HasErrors: null,
            block_2HasErrors: null


        }
    },

    async mounted(){

        // для решения бага с чекбоксом - без отчества
        this.optionalMiddleName()

        if(!this.checkMarkAndProduct()) return;


        await this.finalBlockCheckSmev()


        await this.unlockBlockPhone()
        await this.unlockBlockOther()
        

        await this.presetOrganization()

        await this.unlockBlockProducts()

        this.$nextTick(() => {
            this.monitorContinueLock()
        })

        // this.lockBlockProducts()

    },

    updated(){

        if(!this.disabled_block_4){
            this.createPaymentMethodStr()
        }

        if(this.redirect){
            // this.$router.push({ name: 'contractId-1' })
        }

    },

    watch: {

    "buttons_2": function(){
        
        if(this.buttons_2 === 'success'){

            this.$nextTick(() => {

                this.setDadataHelper({ 
                        ...this.$refs, 
                        location_address: this.$refs.location_address.$refs.location_address 
                }, {

                    init: 'contract',

                    collabs: {
                        group_1: this.collab_1
                    },

                    target: [
                        this.data_1,
                        this.data_2,
                        this.data_3,
                        this.collab_1
                    ]
                })
            })
                
            
        }

    }
  },


    methods:{

        checkBlock_1(){

            const { first_name, last_name, middle_name, snils, dul_ser_passport } = this.$refs

            if(!this.$refs.celendar?.$children[0]?.$children[0]?.$el) return

            const celendar = this.$refs.celendar.$children[0].$children[0]

            const cryteries = [ first_name, last_name, middle_name, snils, dul_ser_passport, celendar]


            let input = null

            let state = false


            for(let item of cryteries){


                input = item.$el.querySelector('input')


                if('middle_name' === input.name && this.req_1.checkName){

                    if(input.value.length < 2){
                        state = true
                    }

                }else if(['first_name', 'last_name'].includes(input.name)){

                    if(input.value.length < 2){
                        state = true
                    }

                }else if(input.name === 'snils'){

                    if(input.value.length < 14){
                        state = true
                    }
                }else if(input.name === 'birth_date'){

                    if(input.value.length < 10){
                        state = true
                    }
            
                }else if(input.name === 'dul_ser_passport'){

                    if(input.value.length < 11){
                        state = true
                    }
                }


            }

            this.block_1HasErrors = state

        },


        optionSelect({option, field}) { // для заполнения селектов
            switch (field) {
                case 'gender':
                    this.data_3.gender = option
                    break
                case 'payment':
                    this.pay.period_auto_pay = option

            }
            this.$store.commit('customErrors/delCustomFieldError', field)
            this.errors = {...this.$store.getters['customErrors/getErrorsApi']}
        },

        async presetOrganization(){
            
            if(this.buttons_2 !== 'success'){
                return;
            }

            const orgs = localStorage.getItem('organizations')
            
            if(!orgs && this.buttons_2 === 'success'){

                await this.$store.dispatch('getOrganization/get', {})

                this.organizations = JSON.parse(localStorage.getItem('organizations'))

            }else{
                this.organizations = JSON.parse(orgs)
            }

            //предзаполнения названия места работы. Айди места работы предзаполняется фун-ей fill_actual
            this.workPlace.name = this.organizations.find(item => item.ID === this.data_3.job_org_id)?.NAME
        },

        createPaymentMethodStr () {

            let autoPayStr = this.pay.check_auto_pay ? '_auto' : ''
            let periodAutoPayStr = ''

            if (this.pay.check_auto_pay) {
                periodAutoPayStr = (this.pay.period_auto_pay === '30') ? '': '_90'

            }

            this.paymentString = `acquiring_bank${autoPayStr + periodAutoPayStr}`

        },

        checkMarkAndProduct(){

            let stepError = ''

            if(!this.data_4.product_type_id){

                stepError = 'Вы не выбрали продукт!'

            }else if(typeof this.check_boxes_step_2?.markMail !== 'boolean' ){

                stepError = 'Вы пропустили второй шаг заполнения договора!'

            }else{
                return true
            }

            this.errorsAPI({error: stepError}, 'twoForm')

            this.disabled_block_1 = true
        },

        async preHandle(){

            const data = {...this.data_1}

            let hasErrors = await this.$deactivateScheme.more.call(this, {
                activateForm: 'twoForm',
                getHasErrors: 'twoForm',
                choice: ['middleNameScheme']
            })


            if(hasErrors) return;

            if(!this.req_1.reqName){
                delete data.middle_name
            }



            this.handleSubmit({
                    data: {
                        ...data,
                        marketing_mailings: this.check_boxes_step_2.markMail,
                    },
                        childFunc: this.childFunc_1.bind(this),
                        myselfRedirect: true
                    })
        },

        async childFunc_1({ error, data_errors }){

            //есть/нет отчества
            this.$cookies.set('check-middle_name', this.req_1.checkName)


            if(!error && !data_errors){

                await this.finalBlockCheckSmev()
                this.unlockBlockPhone()

            }

            this.errorsAPI({error, data_errors}, 'twoForm')

        },

        async childFunc_2({ error, data_errors }){

            if(!error && !data_errors){

                switch(this.nameForm){

                    case 'thourForm':
                        this.$cookies.set('check-location_address', this.req_2.checkName)
                        await this.unlockBlockProducts()
                        return;

                    case 'fiveForm':
                        this.$cookies.set('check-auto_pay', this.pay.check_auto_pay)
                        await this.finalizingActions()
                        return;

                }

            }

            this.errorsAPI({error, data_errors}, this.nameForm)
        },

        async finalizingActions(){

            const form = 'fiveForm'

            await this.$store.dispatch('confirmSms/confirmSms', {
                                    verify: {
                                        code: this.codePuck.code,
                                        prevalidation: false,
                                    },
                                    init: 'contract'
                                })

            if(!this.smsConfirmError && !this.smsConfirmData_errors){


                await this.$store.dispatch('finalizeContract/start')

                if(!this.finalData_errors && !this.finalError){

                    this.lockBlockProducts()

                    console.log('Договор успешно отправлен на финализацию!')
                    //финальная точка оформления
                    //ждем статуса 3, об успешной финализации
                    

                    // чистим куки смс сообщений
                    await this.clearProjectSms()


                    this.$router.push({ name: 'contractFinal' })

                }

                this.errorsAPI({
                    error: this.finalError,
                    data_errors: this.finalData_errors

                }, form)
                return;
            }

            this.errorsAPI({
                    error: this.smsConfirmError,
                    data_errors: this.smsConfirmData_errors

                }, form)
        },

        async continueProject(){

            let myselfRedirect = null,
                childFunc = this.childFunc_2.bind(this),
                data = {}

            //работаем в соответствии с разблокировкой форм
            if(!this.disabled_block_3 && this.disabled_block_4){

                this.nameForm  = 'thourForm'
                myselfRedirect = true
                data           = { ...this.data_3,
                                    ...this.collab_1,
                                    dul_subdivision_code: this.collab_1.dul_subdivision_code?.replace(' ', '-')
                }

            }else if(this.disabled_block_3 && !this.disabled_block_4){

                
                this.nameForm  = 'fiveForm'
                myselfRedirect = true
                
                data = {
                    vznos_summ:     +this.data_4.vznos_summ,
                    payment_method: this.paymentString,
                    payment_date:   this.pay.datePay,

                }

            }

            let hasErrors = await this.$deactivateScheme.more.call(this, {
                activateForm: this.nameForm,
                getHasErrors: this.nameForm,
                // choice: [this.nameForm === 'thourForm' ? 'locationAddressScheme' : '']
            })

            if(!this.nameForm) return;

            //создаем событие сабмит, и получаем DOM элемент нужной формы
            const event = new Event("submit", { cancelable: true }),
                   form = this.$refs[this.nameForm].$el

            //вызываем событие сабмита
            form.dispatchEvent(event);


            if(hasErrors) return;

            if(this.nameForm === 'thourForm' && !this.req_2.reqName){

                data.location_address = data.registration_address
                data.location_kladr   = data.registration_kladr
                data.location_fias    = data.registration_fias
                data.location_index   = data.registration_index
                data.location_json    = data.registration_json
            }
            delete data.registration_json // добавил чтобы договора просто проходили проверку апи
            delete data.location_json


            await this.handleSubmit({ data, childFunc, myselfRedirect })

        },

        async sendSms(){

            const { error, data_errors } = await this.handleSubmit({
                                                        data: this.data_2,
                                                        myselfRedirect: true
                                                    })

            if(!error && !data_errors){

                await this.$store.dispatch('sendSms/sendSms', {
                        action: 'click',
                        init: 'contract'
                    })

                if(!this.smsSendError){
                    this.buttons_2 = 'confirm'
                }

            }


            this.errorsAPI({
                    error: error || this.smsSendError,
                    data_errors
                }, 'threeForm')


        },

        async confirmSms(){

            this.optionalPhone()

            await this.$store.dispatch('confirmSms/confirmSms', {
                        verify: {
                            code: this.codePuck.code,
                            prevalidation: true,
                        },
                        init: 'contract'
                    })

            const data_errors = this.smsConfirmData_errors,
                        error = this.smsConfirmError


            if(!error && !data_errors){

                await this.unlockBlockOther()
            }

            this.errorsAPI({error, data_errors}, 'threeForm')
        },

        errorsAPI({ error, data_errors }, nameForm){

            this.errorsFromApi[nameForm] = error

            this.errorsSyntax({
                    serverError: [error],
                    fieldErrors: data_errors,
                    api: true
                }, nameForm);
        },

        errorsSyntax({ serverError = [], fieldErrors = {}, api = false }, nameForm){

            this.$formulate.handle({

                api: api,

                aggregate: fieldErrors,
                formErrors: serverError,

            }, nameForm)

        },

        async unlockBlockPhone(){

            //телефон разблокируется как только заполнятся все поля без ошибок
            //если поля необязательны к заполнению, заполнять их null
            //true убрать

            const data = { ...this.data_1 }

            delete data.middle_name

            const values = Object.values(data).filter(prop => prop)

            if(values.length === 5){
                this.disabled_block_2 = false
            }

        },

        async unlockBlockOther(){

            const verifyID = this.fixationVerifyPhoneStage('getID'),
                    currID = this.$cookies.get('id_current_contract'),
                    dulTitle = this.collab_1.dul_subdivision_title


            //true убрать
            console.log(verifyID, currID)
            if(currID && (verifyID === currID || dulTitle)){


                this.buttons_2 = 'success'
                this.disabled_block_3 = false

                await this.presetOrganization()

            }

        },

        async unlockBlockProducts(){

            //продукты разблокируется как только заполнятся все поля без ошибок
            //если поля необязательны к заполнению, заполнять их null
            //true убрать

            await this.$store.dispatch('getProducts/get', {})

            const { min, max } = this.productData[this.data_4.product_type_id].vznos_summ

            this.localShemes.firstPay[3] = ['between', min, max]


            const { birth_place, dul_date, dul_subdivision_code, gender,
                    dul_subdivision_title, registration_address, email, job_org_id } = { ...this.data_3, ...this.collab_1 }

            let criteriesUnlock = [ birth_place, dul_date, dul_subdivision_code,
                                    dul_subdivision_title, registration_address, email, gender, job_org_id ]
                                .filter(prop => prop)

            if(criteriesUnlock.length === 8){

                this.disabled_block_3 = true
                this.disabled_block_4 = false

                this.view_pay_block = true
            }
        },

        lockBlockProducts(){

            if(this.data_4.vznos_summ){
                this.disabled_block_4 = true
            }

        },

        monitorContinueLock(){

            const needForm_1 = this.$formulate.registry
                                            ?.get('thourForm')
                                            ?.hasErrors

            const needForm_2 = this.$formulate.registry
                                            ?.get('fiveForm')
                                            ?.hasErrors


            if(needForm_1 === false && !this.disabled_block_3
                ||
               needForm_2 === false && !this.disabled_block_4)
            {

                this.continue_btn_lock = false

            }else if(typeof needForm_1 === 'boolean' || typeof needForm_2 === 'boolean'){

                this.continue_btn_lock = true

            }else{
                console.log('Ошибка. Вы редактировали документ, и сохранили. Не повлияет на production!')
            }


        },

        async finalBlockCheckSmev(){

            const data = { ...this.data_1 }

            delete data.middle_name

            const values = Object.values(data).filter(prop => prop)
            let on_off   = true

            if(values.length !== 5){
                on_off = false
            }

            await this.$store.dispatch('checkSmev/check')

            //если данных достаточно для проверки в смэв, диcейблим инпуты
            this.disabled_block_1 = on_off
            this.activePreloader = on_off

        },

        fixationVerifyPhoneStage(action){

            const { code, id } = (~['getID', 'getCode'].indexOf(action))
                                    ? this.$cookies.get('project_verify') || {}
                                    : {}

            switch(action){

                case 'getID':
                    return id || ''

                case 'getCode':
                    return code || ''

            }
        },


        optionalMiddleName(){

            this.$changeCheckInput({
                            reqDis: this.req_1,
                            localScheme: this.customSchemes,
                            propLocal: 'middleNameScheme',
                            nameGlobalScheme: 'middleNameScheme'
                        })
        },

        optionalLocationAddress(){

            this.$changeCheckInput({
                            reqDis: this.req_2,
                            localScheme: this.customSchemes,
                            propLocal: 'locationAddressScheme',
                            nameGlobalScheme: 'address'
                        })
        },  

        optionalPhone(){

            this.$deactivateScheme.one({
                            activate: true,
                            localScheme: this.customSchemes.phoneScheme,
                            nameGlobalScheme: 'phone'
                        })
        },

        
        optionalDatePay(){
            
            setTimeout(() => {


                // const hasErrors = this.$formulate.registry.get('fiveForm').hasErrors

                // this.continue_btn_lock = hasErrors ? true : false

                if(this.pay.check_auto_pay && !this.pay.datePay || this.$refs.firstPay.hasErrors){


                    console.log('заблокировал кнопку')
                    this.continue_btn_lock = true

                }else{
                    this.continue_btn_lock = false
                }



            })

            this.errorsSyntax({}, 'fiveForm')
            this.monitorContinueLock()

            
        },

    },

    components: {
        vSmsVisualSeparateSender,
        vSelect,
        NoStreetHouseInput,
        vSuggestionInput,
        Celendar
    }

}

</script>
